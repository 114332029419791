<script setup lang="ts">
const colorMode = useColorMode()
const isLightMode = computed(() => colorMode.value === 'light')

const onChangeTheme = () => {
  if (colorMode.value === 'dark') {
    // colorMode.preference = 'light'
    colorMode.preference = 'dark'
  } else {
    colorMode.preference = 'dark'
  }
}
const themes = [
  {
    label: 'Light mode',
    value: 'light',
  },
  {
    label: 'Dark mode',
    value: 'dark',
  },
]
</script>

<template>
  <Tooltip
    placement="right"
    content="Change theme"
    class="bg-primary font-medium"
    outer-class="w-full "
  >
    <FormKit
      type="listbox"
      :options="themes"
      :value="isLightMode ? 'light' : 'dark'"
      class="w-24"
      @input="onChangeTheme"
    />
  </Tooltip>
</template>
